import React from "react";
import {Link, graphql} from "gatsby";
import {RichText} from "prismic-reactjs";
import htmlSerializer from "../utils/htmlSerializer";

// COMPONENTS
import Head from "../components/head/head";
import Navigation from "../components/navigation";
import Header from "../components/header/header";

export const query = graphql`
  query CoursePostQuery($uid: String) {
    prismic {
      allCourses(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
              type
            }
            course_title
            course_author
            course_desc
            course_thumb
            course_content
            video_list {
                vid_title
                vid_desc
                vid_embed
            }
          }
        }
      }
    }
  }
`;

// Display the title, date, and content of the Post
const PostBody = ({ coursePost }) => {
  const titled = coursePost.course_title.length !== 0;
  return (
    <div>
      <div>
        <Head title={RichText.asText(coursePost.course_title)} />
        <Navigation />
        <Header page_title={RichText.asText(coursePost.course_title)} />

        <div className="container content relative pb-l">
          <div className="flex flex-center column pt-m pb-m pl-s pr-s">
            <Link to="/">Terug naar overzicht</Link>
            {RichText.render(coursePost.course_content)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default (props) => {
  //   const lesson = data.prismic.allCourses.edges;
  const course = props.data.prismic.allCourses.edges.slice(0, 1).pop();
  if (!course) return null;


  return (
    <div>
      <PostBody coursePost={course.node} />
      {/* {lesson.map((list, index) => {
        return (
          <div key={index}>
            <h1>{RichText.asText(list.node.video_list[0].vid_title)}</h1>
            <h2>{RichText.render(list.node.video_list[0].vid_desc)}</h2>
            {list.node.course_thumb.url}
            {list.node.video_list[0].vid_embed.html}
            <iframe
              width="560"
              height="315"
              title={RichText.render(list.node.video_list[0].vid_title)}
              src="https://www.youtube.com/embed/YJQhpNqczIY"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        );
      })} */}
    </div>
  );
};
